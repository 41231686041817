var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "content-block",
        { attrs: { title: "Trackers" } },
        [_c("flat-cube", { on: { selected: _vm.faceSelected } })],
        1
      ),
      _c("user-day-tracker", {
        attrs: {
          user: _vm.current_user,
          show_tracking_block: false,
          theme: "dark",
        },
      }),
      _c(
        "content-block",
        { attrs: { title: "Timeline" } },
        [
          _c(
            "div",
            {
              directives: [{ name: "only-admin", rawName: "v-only-admin" }],
              staticClass: "users",
              attrs: { slot: "right" },
              slot: "right",
            },
            _vm._l(_vm.users, function (user) {
              return _c(
                "div",
                {
                  key: user.id,
                  staticClass: "user-color",
                  class: [
                    `user_${user.id}`,
                    { active: _vm.active_users.includes(user.id) },
                  ],
                  style: {
                    backgroundColor: user.color || "#1989fa",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.toggleUser(user)
                    },
                  },
                },
                [_c("span", [_vm._v(_vm._s(user.name))])]
              )
            }),
            0
          ),
          _c("sessions-timeline", {
            attrs: { active_users: _vm.active_users },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }