var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("timeline", {
        ref: "timeline",
        staticClass: "timeline",
        attrs: { groups: _vm.groups, items: _vm.items, options: _vm.options },
        on: {
          select: _vm.selected,
          "items-mounted": _vm.getDataSet,
          currentTimeTick: _vm.currentTimeTick,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }