var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        _vm._l(_vm.range(0, 1), function (row) {
          return _c(
            "el-col",
            { key: `row_${row}`, attrs: { span: 12, md: 12, sm: 24, xs: 24 } },
            [
              _c(
                "ul",
                _vm._l(_vm.range(row * 4, 4 + row * 4 - 1), function (face_id) {
                  return _c("flat-cube-face", {
                    key: face_id,
                    attrs: { id: "face_" + face_id, face_id: face_id },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("selected", face_id)
                      },
                      showTrackerDetail: _vm.handleShowTrackerDetail,
                    },
                  })
                }),
                1
              ),
            ]
          )
        }),
        1
      ),
      _c("tracker-detail", {
        attrs: { show_dialog: _vm.show_dialog, face_id: _vm.selected_face_id },
        on: {
          "update:show_dialog": function ($event) {
            _vm.show_dialog = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }